<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";

/**
 * Advanced-table component
 */
export default {
  page: {
    title: "Operational Checklist Report Template",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: { Layout, PageHeader,Multiselect },
  data() {
    return {
      tableData: [],
      restBranchArr:[],
      restBranchID:"",
      title: "Operational Checklist Report Template",
      items: [
        {
          text: "View",
        },
        {
          text: "Operational Checklist Report Template",
          active: true,
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      fields: [
        {
          key: "Action",
          sortable: false,
        },
        {
          key: "templateTitle",
          sortable: true,
        },
        {
          key:"createdBy",
          sortable:true,
        },
        {
          key: "created",
          sortable: true,
        },
      ],
      path: "",
      editRight: "",
      deleteRight: "",
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
    // this.readComplianceTemplateData();
    this.getAllRestaurantBranches();
    this.checkUserRights();
  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    checkUserRights() {
      this.path = this.$route.path;
      this.axios.post(this.$loggedRole+"/checkUserRights", {
        empID: this.$storageData.profile.pid,
        empTypeID: this.$storageData.profile.empTypeID,
        path: this.path,
      })
      .then((result) => {
        this.editRight = result.data.data[0].isEdit;
        this.deleteRight = result.data.data[0].isDelete;
      });
    },

    readComplianceTemplateData(){
      this.axios
      .post(this.$loggedRole+"/viewAllComplianceTemplate" ,{
          'restBranchID':this.restBranchID.restBranchID,
          empTypeID:this.$storageData.profile.empTypeID,
          empID:this.$storageData.profile.pid,
          loginTypeID:this.$storageData.login_type
        }
      )
      .then((response) => {
        //Then injecting the result to datatable parameters.
        this.tableData = response.data.data;
      });
    },

    getAllRestaurantBranches(){
      this.axios.post(this.$loggedRole+"/getAllRestaurantBranches", {
        empTypeID:this.$storageData.profile.empTypeID,
        empID:this.$storageData.profile.pid,
        loginTypeID:this.$storageData.login_type
      })
      .then((result)=>{
        this.restBranchArr = result.data.data;
      });
    },
  },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-md-4 mb-2">
        <label class="form-label" for="formrow-endTime-input">Select Restaurant Branch</label>
        <multiselect v-model="restBranchID" :disabled="isEdit" :options="restBranchArr" track-by="restBranchID" label="restaurantName"
          @input="readComplianceTemplateData"></multiselect>
      </div>
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mt-4">
              <div class="col-sm-12 col-md-4" style="margin-bottom:15px;">
                <div role="group" class="btn-group">
                  <button type="button" class="btn btn-themeBrown" v-if="this.$storageData.login_type == 1">Excel</button>
                  <!-- <button type="button" class="btn btn-themeBrown">Column Visibility</button> -->
                </div>
              </div>
              <div class="col-sm-12 col-md-4">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show &nbsp;&nbsp;
                    <b-form-select style="margin-left:5px; margin-right:5px" v-model="perPage" size="sm" :options="pageOptions"></b-form-select>
                    &nbsp;&nbsp;&nbsp; entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-4 row">
                <div id="tickets-table_filter" class="dataTables_filter text-md-end">
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input v-model="filter" type="search" placeholder="Search..." class="form-control form-control-sm ms-2"></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">s
              <b-table
                striped hover
                outlined
                bordered
                :items="tableData"
                :fields="fields"
                responsive
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
              >
                <template  v-slot:cell(Action)="data">
                  <router-link :to="{ name: 'complianceTemplate', params: { id: data.item.complianceTemplateID , type:'edit' } }" v-if="editRight == 1">
                    <i class="uil uil-edit-alt" title="Edit" style="font-size: 19px;"></i>
                  </router-link>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-end">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<style scoped>
.my-class { 
  max-width: 10px !important;
}
</style>